import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Flex, Loader, Text, Tooltip } from "@fluentui/react-northstar";
import { toast } from "react-toastify";
import { Button, CloseIcon, AcceptIcon } from "@fluentui/react-northstar";
import { LoadingButton } from "@mui/lab";

import { Edit, Trash } from "react-feather";
import { Input } from "@mui/material";
import "../event.css";
import "./eventTag.scss";
import { AlertDialog } from "../../../components/Dialog/AlertDialog";
import { useTranslation } from "react-i18next";

const END_POINT = process.env.REACT_APP_EP_URL;

export const EventTag = (props) => {
  const [tag, setTag] = useState([]);
  const [load, setLoad] = useState(true);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState({
    id: "",
    title: "",
    isEdit: false,
  });
  // const [addTag, setAddTag] = useState({
  //   isEdit: false,
  //   title: "",
  // });

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.user.slug && props.user.accessToken) {
      getTag(props.user.slug, props.user.accessToken);
    }
  }, [props]);

  const getTag = (slug, token) => {
    axios
      .get(`${END_POINT}/api/tag/all${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setTag(res.data.result);
      })
      .catch((err) => {
        setLoad(false);
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
      });
  };

  const handleAddTag = () => {
    setLoading(true);
    axios
      .post(
        `${END_POINT}/api/tag${props.user.slug}`,
        {
          title: props.addTag.title,
        },
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        props.setAddTag({ isEdit: false, title: "" });
        getTag(props.user.slug, props.user.accessToken);
      })
      .catch((err) => {
        setLoading(false);
        setEdit({ id: "", title: "", isEdit: false });
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    axios
      .put(
        `${END_POINT}/api/tag/${edit.id}${props.user.slug}`,
        {
          title: edit.title,
        },
        {
          headers: {
            Authorization: `Bearer ${props.user.token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setEdit({ id: "", title: "", isEdit: false });
        const idx = tag.findIndex((data) => data.id === edit.id);
        if (idx != -1) {
          tag[idx].title = edit.title;
          setTag([...tag]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setEdit({ id: "", title: "", isEdit: false });
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
      });
  };

  const handleDelete = () => {
    axios
      .delete(`${END_POINT}/api/tag/${edit.id}${props.user.slug}`, {
        headers: {
          Authorization: `Bearer ${props.user.token}`,
        },
      })
      .then((res) => {
        setEdit({ id: "", title: "", isEdit: false });
        setTag(tag.filter((data) => data.id !== edit.id));
      })
      .catch((err) => {
        setEdit({ id: "", title: "", isEdit: false });
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return load ? (
    <Loader size="medium" style={{ paddingTop: "50px" }} />
  ) : (
    <>
      <AlertDialog
        handleClose={handleClose}
        handleOk={handleDelete}
        open={open}
      />
      <div className="btsp setting-event-tag-container">
        <Button
          content={t("setting").event[3]}
          primary
          className="ml-auto d-block"
          onClick={() => props.setAddTag({ isEdit: true })}
        />
        {props.addTag.isEdit && (
          <>
            <div className="main_card_div my-3 d-flex justify-content-between">
              <Flex column fill gap="gap.smaller">
                <Input
                  value={props.addTag.title}
                  fullWidth
                  sx={{ fontSize: 12 }}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      props.setAddTag({
                        ...props.addTag,
                        title: e.target.value,
                      });
                    }
                  }}
                />
                <Flex.Item align="end">
                  <Text content={`${props?.addTag?.title?.length || 0}/50`} />
                </Flex.Item>
              </Flex>
              <div className="d-flex ml-2">
                <LoadingButton
                  loading={loading}
                  startIcon={!loading && <AcceptIcon style={{ margin: 0 }} />}
                  iconOnly
                  title="Submit"
                  onClick={handleAddTag}
                  disabled={!props.addTag.title || !props.addTag.title.trim()}
                  style={{
                    color:
                      !props.addTag.title || !props.addTag.title.trim()
                        ? "gray"
                        : "#585A96",
                    borderRadius: 5,
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem",
                    border: "1px solid rgb(225, 223, 221)",
                    width: 33,
                    minWidth: 0,
                  }}
                />
                <Button
                  icon={<CloseIcon />}
                  iconOnly
                  title="Cancel"
                  onClick={() =>
                    props.setAddTag({
                      isEdit: false,
                      title: "",
                    })
                  }
                  className="mx-2"
                  style={{ color: "#DE5854", borderRadius: 5 }}
                />
              </div>
            </div>
          </>
        )}
        <div className="event-tags-wrapper">
          {tag.length > 0 &&
            tag.map((data) => {
              return (
                <>
                  <div className="main_card_div my-3 d-flex justify-content-between">
                    {edit.isEdit && edit.id === data.id ? (
                      <Flex column fill gap="gap.smaller">
                        <Input
                          value={edit.title}
                          fullWidth
                          sx={{ fontSize: 12 }}
                          onChange={(e) => {
                            if (e.target.value.length <= 50) {
                              setEdit({ ...edit, title: e.target.value });
                            }
                          }}
                        />
                        <Flex.Item align="end">
                          <Text content={`${edit?.title?.length || 0}/50`} />
                        </Flex.Item>
                      </Flex>
                    ) : (
                      <Tooltip
                        content={data.title}
                        trigger={
                          <span
                            className=""
                            style={{
                              display: "block",
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {data.title}
                          </span>
                        }
                      />
                    )}
                    <div className="d-flex ml-2">
                      {edit.isEdit && edit.id === data.id ? (
                        <>
                          <LoadingButton
                            loading={loading}
                            startIcon={!loading && <AcceptIcon />}
                            iconOnly
                            title="Submit"
                            onClick={handleEdit}
                            disabled={!edit.title || !edit.title.trim()}
                            style={{
                              color:
                                !edit.title || !edit.title.trim()
                                  ? "gray"
                                  : "#585A96",
                              borderRadius: 5,
                              boxShadow:
                                "rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem",
                              border: "1px solid rgb(225, 223, 221)",
                              width: 33,
                              minWidth: 0,
                            }}
                          />
                          <Button
                            icon={<CloseIcon />}
                            iconOnly
                            title="Cancel"
                            onClick={() => {
                              setEdit({
                                id: "",
                                title: "",
                                isEdit: false,
                              });
                            }}
                            className="mx-2"
                            style={{ color: "#DE5854", borderRadius: 5 }}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            icon={<Edit />}
                            iconOnly
                            title="Edit tag"
                            onClick={() => {
                              setEdit({
                                id: data.id,
                                title: data.title,
                                isEdit: true,
                              });
                            }}
                            style={{ color: "#585A96", borderRadius: 5 }}
                          />
                          <Button
                            icon={<Trash />}
                            iconOnly
                            title="Delete tag"
                            onClick={() => {
                              setOpen(true);
                              setEdit({ id: data.id, isEdit: false });
                            }}
                            className="mx-2"
                            style={{ color: "#DE5854", borderRadius: 5 }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};
