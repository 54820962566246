import { Button, Dialog } from "@fluentui/react-northstar";
import axios from "axios";
import moment from "moment";
import { object } from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";

import userSvg from "../../Assets/images/svg/user.svg";
import { AlertDialog } from "../../components/Dialog/AlertDialog";
import Header from "../../components/Header/Header";
import CustomProgressIndicator from "../../components/Loader/CustomProgressIndicator";
import { NotificationAlert } from "../../components/Notification/Notification";
import { ResponsiveImage } from "../../components/blocks/Images";
import EventForm from "../../components/forms/Event/index";

const url = process.env.REACT_APP_EP_URL;

class EditEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accDlg: false,
      event: null,
      categories: [],
      tags: [],
      teacherData: [],
      isSubmitting: false,
      isLoading: false,
      isDeleteModalOpen: false,
      deleteData: null,
      isDeleting: false,
    };
  }

  componentDidMount() {
    this.getEvent();
    this.getCategories();
    this.getTags();
    this.getTeachers();
  }

  getTextContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data);
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  getLinkContentFromUrl = (url) => {
    if (url) {
      try {
        return fetch(url)
          .then((response) => response.text())
          .then((data) => data.split(","));
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  formatFileLinks = (url) => {
    if (url) {
      try {
        const formattedUrls = url.split(",");

        return Array.isArray(formattedUrls) ? formattedUrls : [];
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  formatEventData = async (eventData) => {
    try {
      const eventAttachments = eventData?.EventAttachments;

      if (Array.isArray(eventAttachments) && eventAttachments.length) {
        const textInfo = await this.getTextContentFromUrl(
          eventAttachments?.find((item) => item.type == "text")?.info
        );
        const linkInfo = await this.getLinkContentFromUrl(
          eventAttachments?.find((item) => item.type == "link")?.info
        );

        const fileInfo = this.formatFileLinks(
          eventAttachments?.find((item) => item.type == "file")?.info
        );

        const getContentByType = (type) =>
          eventAttachments?.find((item) => item.type == type);

        const attachments = [
          // {
          //   ...(getContentByType("text") ?? {}),
          //   type: "text",
          //   info: textInfo,
          // },
          // {
          //   ...(getContentByType("voice") ?? {}),
          //   type: "voice",
          //   info: getContentByType("voice")?.info || "",
          // },
          // {
          //   ...(getContentByType("video") ?? {}),
          //   type: "video",
          //   info: getContentByType("voice")?.info || "",
          // },
          {
            ...(getContentByType("link") ?? {}),
            type: "link",
            info: linkInfo,
          },
          {
            ...(getContentByType("file") ?? {}),
            type: "file",
            info: Array.isArray(fileInfo) && fileInfo.length ? fileInfo : [],
          },
        ];

        eventData.attachments = attachments;
      } else {
      }
    } catch (error) {
      console.log(error);
    }
    return eventData;
  };

  getEvent = async () => {
    this.setState({ isLoading: true });
    const teacherId = this.props.user.mail;
    await axios
      .get(
        `${url}/api/events/${teacherId}${this.props.user.slug}&id=${this.props.match.params.id}`
      )
      .then(({ data: { result } }) => this.formatEventData(result))
      .then((result) => {
        result.speakers =
          (!!result.speakers && JSON.parse(result.speakers)) || [];
        result.files = (!!result.files && JSON.parse(result.files)) || [];
        result.startDate =
          result.startDate && moment(result.startDate).isValid()
            ? moment
                .utc(result.startDate)
                .local()
                .format("YYYY-MM-DDTHH:mm:ssZ")
            : null;
        result.endDate =
          result.endDate && moment(result.endDate).isValid()
            ? moment.utc(result.endDate).local().format("YYYY-MM-DDTHH:mm:ssZ")
            : null;
        this.setState(
          {
            event: result,
          },
          () => {
            // this.setSpeakers();
          }
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
        console.log(err);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  getCategories = () => {
    axios
      .get(`${url}/api/category/all${this.props.user.slug}`)
      .then(({ data: { result } }) => {
        this.setState({
          categories: result,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  getTags = () => {
    axios
      .get(`${url}/api/tag/all${this.props.user.slug}`)
      .then(({ data: { result } }) => {
        this.setState({
          tags: result,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  getTeachers = () => {
    axios
      .post(
        `${url}/api/teacher/get-teachers${this.props.user.slug}`,
        {},
        {
          headers: {
            Authorization:
              this.props.user.tokenType + " " + this.props.user.accessToken,
          },
        }
      )
      .then(({ data = {} }) => {
        const teachers = data.result
          .map((teacher) => {
            return {
              header: teacher.TeacherName,
              image: teacher.UrlPhoto
                ? teacher.UrlPhoto + this.props.user.SASToken
                : userSvg,
              content: teacher.Facultad,
              key: teacher.TeacherEmailID,
            };
          })
          .filter((value, index, self) => self.indexOf(value) === index);
        this.setState({ teacherData: teachers }, () => {
          this.setSpeakers();
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
        if (err.response?.status === 401) {
          this.setState({ accDlg: true });
        }
      });
  };

  setSpeakers = async () => {
    const { event, teacherData } = this.state;
    if (!!event && !!event.speakers) {
      let selectedSpeakers = event.speakers;
      let speakers = [];
      if (
        selectedSpeakers &&
        Array.isArray(selectedSpeakers) &&
        selectedSpeakers.length
      ) {
        speakers = await Promise.all(
          !!selectedSpeakers &&
            selectedSpeakers?.length &&
            selectedSpeakers.map((x) => {
              return teacherData.find((y) => x == y.key);
            })
        );
      }
      event.speakers = speakers;
      this.setState({
        event,
      });
    }
  };

  saveAttachments = async ({ eventId, uploadEventAttachmentsToAzure }) => {
    try {
      return await uploadEventAttachmentsToAzure(eventId, true);
    } catch (error) {
      NotificationAlert(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong",
        "error"
      );
      return;
    }
  };

  onSubmit = async (event, uploadEventAttachmentsToAzure) => {
    const isDraftEvent = event.status === "draft";
    this.setState({ isSubmitting: true });
    if (!!event.speakers) {
      event.speakers = JSON.stringify(event.speakers);
    }
    const eventData = new FormData();

    Object.keys(event).forEach((e) => {
      if (e !== "attachments") {
        if (e === "categories") {
          if (Array.isArray(event[e])) {
            for (let category of event[e]) {
              eventData.append("categories", category);
            }
          } else if (typeof event[e] === "string") {
            eventData.append("categories", event[e]);
          }
        } else if (e === "tags") {
          if (Array.isArray(event[e])) {
            for (let tag of event[e]) {
              eventData.append("tags", tag);
            }
          } else if (typeof event[e] === "string") {
            eventData.append("tags", event[e]);
          }
        } else if (e === "isMSTeamMeeting") {
          eventData.append(e, !!event["isMSTeamMeeting"]);
        } else if (e === "isGoogleMeetMeeting") {
          eventData.append(e, !!event["isGoogleMeetMeeting"]);
        } else if (["eventType", "meetingURL", "meetingId"].includes(e)) {
          let isMsMeeting = !!event.isMSTeamMeeting;
          if (!isMsMeeting) {
            eventData.append(e, "");
          } else {
            eventData.append(e, event[e] || "");
          }
        } else {
          eventData.append(e, event[e] || "");
        }
      } else {
        for (let file of event[e]) {
          if (file.url?.includes("blob.core.windows.net")) {
            eventData.append("attachments", file.url || file.originFileObj);
          } else {
            eventData.append("attachments", file.originFileObj);
          }
        }
      }
    });

    const headers = {
      Authorization:
        this.props.user.tokenType + " " + this.props.user.accessToken,
    };

    if (
      !!event.isGoogleMeetMeeting ||
      event.googleMeetId ||
      event.googleMeetURL
    ) {
      try {
        let tokenData = null;
        let googleMail = null;
        let googleToken = localStorage.getItem("googleToken");
        let googleMeetToken = localStorage.getItem("GoogleMeetToken");
        let token = null;

        if (googleMeetToken && JSON.parse(googleMeetToken)?.accessToken) {
          tokenData = JSON.parse(googleMeetToken);
        } else if (googleToken && JSON.parse(googleToken)?.accessToken) {
          tokenData = JSON.parse(googleToken);
        }
        if (tokenData?.accessToken && tokenData.profileObj?.email) {
          token = tokenData.accessToken;
          googleMail = tokenData.profileObj.email;

          headers.AuthorizationForMeet = "Bearer " + token;
          eventData.append("googleMail", googleMail);
        } else {
          if (!isDraftEvent) {
            eventData.set("isGoogleMeetMeeting", false);
          }
        }
      } catch (error) {}
    }

    try {
      let tokenData = null;
      let MSTeamsToken = localStorage.getItem("MSTeamsToken");
      let token = null;
      let microsoftMail = null;
      if (MSTeamsToken && JSON.parse(MSTeamsToken)?.accessToken) {
        tokenData = JSON.parse(MSTeamsToken);
      }
      if (tokenData && tokenData.account?.idTokenClaims?.email) {
        token = tokenData.accessToken;
        microsoftMail = tokenData.account.idTokenClaims.email;

        headers.AuthorizationForMSTeams = "Bearer " + token;
        eventData.append("microsoftMail", microsoftMail);
      }
    } catch (error) {}

    try {
      const attachmentResponseData = await this.saveAttachments({
        eventId: event.id,
        uploadEventAttachmentsToAzure,
      });

      const { data } = await axios.put(
        `${url}/api/events/${event.id}${this.props.user.slug}`,
        eventData,
        {
          headers,
        }
      );

      if (data?.status?.toLowerCase() === "success") {
        this.props.history.push(`/events/my-events`);
      }
    } catch (err) {
      NotificationAlert(err?.response?.data?.message || err?.message, "error");
      console.log(err.response);
      if (err?.response?.status === 400 || err?.response?.status === 409) {
        // setDialog(true);
      }
      console.log(err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  deleteEvent = (event) => {
    this.setState({ isDeleting: true });
    axios
      .delete(`${url}/api/events/${event.id}${this.props.user.slug}`)
      .then(({ data: { result } }) => {
        this.props.history.push(`/events/my-events`);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
      })
      .finally(() => {
        this.setState({ isDeleting: false });
      });
  };

  render() {
    const props = this.props;

    const {
      event,
      categories,
      tags,
      teacherData,
      accDlg,
      isSubmitting,
      isLoading,
      isDeleting,
    } = this.state;

    const { t } = props;

    const translation = t("events");
    return (
      <>
        <AlertDialog
          handleClose={() => {
            this.setState({ isDeleteModalOpen: false, deleteData: null });
          }}
          handleOk={() => {
            this.deleteEvent(this.state.deleteData);
          }}
          open={this.state.isDeleteModalOpen}
        />

        <Dialog
          open={accDlg}
          onConfirm={() => location.reload()}
          confirmButton={translation.common.authExpire.button}
          content={
            <p className="refreshMsg">
              {translation.common.authExpire.message}
            </p>
          }
          header={translation.common.authExpire.header}
        />
        <Header
          path="events"
          user={{ ...props.user }}
          logout={props.logout.bind()}
          accessToken={this.props.user.accessToken}
          getDb={props.getDb.bind()}
          switchDb={this.props.switchDb}
          selectedExtension={this.props.selectedExtension}
          setSelectedExtension={this.props.setSelectedExtension}
          openWorkSpace={false}
        />
        <CustomProgressIndicator
          isLoading={isSubmitting || isLoading || isDeleting}
          style={{
            progressTrack: {
              backgroundColor: "#ffffff",
            },
          }}
        />

        <div className="event wrapper">
          {event ? (
            <EventForm
              {...this.props}
              event={event}
              onSubmit={this.onSubmit}
              onCancel={() => this.props.history.push("/events/my-events")}
              onDelete={(event) => {
                this.setState({ deleteData: event, isDeleteModalOpen: true });
              }}
              categories={categories}
              tags={tags}
              teacherData={teacherData}
              isLoading={isSubmitting || isDeleting}
            />
          ) : (
            !(isSubmitting || isLoading) && (
              <div
                className="wrapper"
                style={{ textAlign: "center", marginTop: 82 }}
              >
                <ResponsiveImage
                  className="events-pana"
                  src={"/Images/Events/startled-pana.svg"}
                />
                <h2 style={{ marginTop: 20 }}>
                  {translation.body.myEvents.noEventFound}
                </h2>
                <Button
                  className="blank-button"
                  onClick={() => props.history.push("/events/my-events")}
                >
                  {translation.body.myEvents.backToMyEvents}
                </Button>
              </div>
            )
          )}
        </div>
      </>
    );
  }
}

EditEvent.propTypes = {
  query: object,
};

export default withTranslation()(EditEvent);
