import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Tabs from "../Events/Tabs/Tabs";
import "./Student.scss";
import StudentData from "./StudentData";
import StudentProfile from "./StudentProfile/StudentProfile";
import axios from "axios";
import { Flex, Loader } from "@fluentui/react-northstar";

const Student = (props) => {
  const user = {
    ...props.user,
  };
  const [selectedStudentForProfile, setSelectedStudentForProfile] =
    useState(null);
  const [students, setStudents] = useState([]);
  const [assignedStudents, setAssignedStudents] = useState([]);
  const [isLoadingStudents, setIsLoadingStudents] = useState(false);

  const getStudentsAssignedToParent = (email) => {
    setIsLoadingStudents(true);
    axios
      .get(
        `${process.env.REACT_APP_EP_URL}/api/parents/${email}${props.user.slug}`,
        null,
        {
          headers: {
            Authorization: "Bearer " + props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data;
        if (response.length) {
          const modifiedStudents = response.map((student) => {
            return { key: student.StudentEmailID, header: student.StudentName };
          });
          const modifiedStudentsForProfile = response.map((student) => {
            return {
              key: student.StudentEmailID,
              header: student.StudentName,
              ...student,
            };
          });
          setStudents(modifiedStudentsForProfile);
          setSelectedStudentForProfile(modifiedStudentsForProfile[0]);
          setAssignedStudents(modifiedStudents);
        }
        setIsLoadingStudents(false);
      })
      .catch((err) => {
        setIsLoadingStudents(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getStudentsAssignedToParent(user.mail);
  }, []);

  return (
    <div>
      <Header
        path="student"
        user={{ ...props.user }}
        logout={props.logout.bind()}
        accessToken={props.user.accessToken}
        getDb={props.getDb.bind()}
        switchDb={props.switchDb}
        selectedExtension={props.selectedExtension}
        setSelectedExtension={props.setSelectedExtension}
        openWorkSpace={false}
      />
      <Tabs currentTab="Progress">
        <div label="Progress">
          {assignedStudents.length ? (
            <StudentData
              user={{ ...props.user }}
              assignedStudents={assignedStudents}
              isLoadingStudents={isLoadingStudents}
            />
          ) : (
            <Flex
              fill
              hAlign="center"
              vAlign="center"
              column
              style={{ height: "400px" }}
            >
              <p>Students Not Found</p>
            </Flex>
          )}
        </div>
        <div label="Student's profile">
          {isLoadingStudents ? (
            <Flex
              fill
              hAlign="center"
              vAlign="center"
              column
              style={{ height: "400px" }}
            >
              <Loader size="medium" />
            </Flex>
          ) : students.length && selectedStudentForProfile ? (
            <StudentProfile
              isStudentProfile={true}
              students={students}
              selectedStudentForProfile={selectedStudentForProfile}
              setSelectedStudentForProfile={setSelectedStudentForProfile}
              user={{
                ...props.user,
                displayName: selectedStudentForProfile.StudentName,
                name: selectedStudentForProfile.StudentName,
                role: "student",
                mail: selectedStudentForProfile.StudentEmailID,
                UrlPhoto: selectedStudentForProfile.studPhoto?.Foto
                  ? selectedStudentForProfile.studPhoto?.Foto +
                    props.user?.SASToken
                  : null,
              }}
            />
          ) : (
            <Flex
              fill
              hAlign="center"
              vAlign="center"
              column
              style={{ height: "400px" }}
            >
              <p>Students Not Found</p>
            </Flex>
          )}
        </div>
      </Tabs>
    </div>
  );
};

export default Student;
