import React, { Component } from "react";
import Chart from "../Container/Charts";
import { Button } from "@fluentui/react-northstar";
import axios from "axios";
import { withTranslation } from "react-i18next";
import { reportTypes } from "./constant";

const url = process.env.REACT_APP_EP_URL;

let d = new Date();

class SubjectReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this?.props?.user?.slug,
      selectedClass: [],
      LessonDate: [
        new Date(d.getFullYear(), d.getMonth() - 3, d.getDate(), 0, 0, 0, 0),
        new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 0, 0),
      ],
      allClassNames: [],
      filterBy: "Presente",
      checkPhysical: null,
      loading: false,
      dataLoaded: false,
    };
  }

  stateChangeHandler(slug) {
    this.setState({ loading: true });
    axios
      .post(
        `${url}/api/chart/get-chart-by-classname${slug}`,
        {
          ClassName: this.state.selectedClass.length
            ? this.state.selectedClass
            : null,
          LessonDate: this.state.LessonDate,
          ClassType: this.state.checkPhysical,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
          },
        }
      )
      .then((res) => {
        const response = res.data.result;
        const faculty = res.data.faculty;

        this.setState({
          classData: response.sort(
            (a, b) =>
              parseInt(b[this.state.filterBy]) -
              parseInt(a[this.state.filterBy])
          ),
          faculty,
        });

        const classNames = response?.map((lsn) => lsn.ClassName) || [];
        if (this.state.allClassNames.length === 0) {
          this.setState({ allClassNames: classNames });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ loading: false, dataLoaded: true });
      });
  }

  componentDidMount() {
    this.stateChangeHandler(this.state.slug);
  }

  componentWillReceiveProps(nextProps) {
    this.stateChangeHandler(nextProps?.user?.slug);
  }

  filterByHandler(option) {
    console.log("filterByHandler:called");
    this.setState({ filterBy: option }, () => {
      this.setState({
        classData: this.state.classData.sort(
          (a, b) =>
            parseInt(b[this.state.filterBy]) - parseInt(a[this.state.filterBy])
        ),
      });
    });
  }

  render() {
    const { t } = this.props;

    Array.prototype.sum = function (prop) {
      var total = 0;
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += parseInt(this[i][prop]);
      }
      return total;
    };

    let pie1data = {};
    let pie2data = this.state.classData
      ? {
          [t("chart").screens.subject.pie.pie2.dataLbl[0]]:
            this.state.classData.sum("Presente"),
          [t("chart").screens.subject.pie.pie2.dataLbl[1]]:
            this.state.classData.sum("Ausente"),
          [t("chart").screens.subject.pie.pie2.dataLbl[2]]:
            this.state.classData.sum("Retardo"),
          [t("chart").screens.subject.pie.pie2.dataLbl[3]]:
            this.state.classData.sum("Justificado"),
        }
      : null;

    if (this.state.classData) {
      const sortedClassData = this.state.classData.sort(
        (a, b) =>
          parseInt(b[this.state.filterBy]) - parseInt(a[this.state.filterBy])
      );

      const top10ClassData = sortedClassData.slice(0, 10);

      top10ClassData.forEach((classData) => {
        if (classData.ClassName) {
          let key = classData.ClassName.split("-")[1].trim();
          pie1data[key] = parseInt(classData[this.state.filterBy]);
        }
      });
    }

    return (
      this.state.dataLoaded &&
      pie1data &&
      pie2data && (
        <Chart
          showControls={true}
          isLoading={this.state.loading}
          report={reportTypes.attendanceSubject}
          user={this.props.user}
          logout={() => this.props.logout()}
          switchDb={this.props.switchDb}
          getDb={() => this.props.getDb()}
          path={this.props.match.path}
          header={{
            report: t("chart").screens.header.reports[0],
            lable: t("chart").screens.header.lable,
          }}
          startDate={this.state.LessonDate[0]}
          endDate={this.state.LessonDate[1]}
          startChangeHandler={(selectedDate) => {
            let LessonDate = [...this.state.LessonDate];
            LessonDate[0] = selectedDate;
            this.setState({ LessonDate }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          endChangeHandler={(selectedDate) => {
            let LessonDate = [...this.state.LessonDate];
            LessonDate[1] = selectedDate;
            this.setState({ LessonDate }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          showClassType={true}
          allClassNames={this.state.allClassNames}
          checkPhysical={this.state.checkPhysical}
          setCheckPhysical={(classType) => {
            this.setState({ checkPhysical: classType }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          stateChangeHandler={(selectedClass) => {
            this.setState({ selectedClass }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          classAdd={(item) => {
            let selectedClass = [...this.state.selectedClass];
            selectedClass.push(item);
            this.setState({ selectedClass }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          classRemove={(item) => {
            let selectedClass = [...this.state.selectedClass];
            selectedClass.splice(selectedClass.indexOf(item), 1);
            this.setState({ selectedClass }, () => {
              this.stateChangeHandler(this.state.slug);
            });
          }}
          customFields={this.state.customFields}
          showLbl={[this.state.selectedClass?.length ? false : true]}
          boxData={
            this.state.classData
              ? {
                  [t("chart").screens.subject.boxs[0]]:
                    this.state.classData.length,
                  [t("chart").screens.subject.boxs[1]]:
                    this.state.classData.sum("Total"),
                  [t("chart").screens.subject.boxs[2]]:
                    this.state.classData.sum("Presente"),
                  [t("chart").screens.subject.boxs[3]]:
                    this.state.classData.sum("Ausente"),
                  [t("chart").screens.subject.boxs[4]]:
                    this.state.classData.sum("Retardo"),
                  [t("chart").screens.subject.boxs[5]]:
                    this.state.classData.sum("Justificado"),
                }
              : null
          }
          pieData={{
            pie1: {
              lable: t("chart").screens.subject.pie.pie1.lbl,
              data: pie1data,
            },
            pie2: {
              lable: t("chart").screens.subject.pie.pie2.lbl,
              data: this.state.classData
                ? {
                    [t("chart").screens.subject.pie.pie2.dataLbl[0]]:
                      this.state.classData.sum("Presente"),
                    [t("chart").screens.subject.pie.pie2.dataLbl[1]]:
                      this.state.classData.sum("Ausente"),
                    [t("chart").screens.subject.pie.pie2.dataLbl[2]]:
                      this.state.classData.sum("Retardo"),
                    [t("chart").screens.subject.pie.pie2.dataLbl[3]]:
                      this.state.classData.sum("Justificado"),
                  }
                : null,
            },
          }}
          columnData={{
            stacked: false,
            radius: 10,
            title: t("chart").screens.subject.column.title,
            lableY: t("chart").screens.subject.column.lableY,
            lableX: t("chart").screens.subject.column.lableX,
            series: this.state.classData
              ? [
                  {
                    name: [t("chart").screens.subject.column.series[0]],
                    data: this.state.classData
                      .slice(0, 7)
                      .map((lsn) => parseInt(lsn.Presente)),
                  },
                  {
                    name: [t("chart").screens.subject.column.series[1]],
                    data: this.state.classData
                      .slice(0, 7)
                      .map((lsn) => parseInt(lsn.Ausente)),
                  },
                  {
                    name: [t("chart").screens.subject.column.series[2]],
                    data: this.state.classData
                      .slice(0, 7)
                      .map((lsn) => parseInt(lsn.Retardo)),
                  },
                  {
                    name: [t("chart").screens.subject.column.series[3]],
                    data: this.state.classData
                      .slice(0, 7)
                      .map((lsn) => parseInt(lsn.Justificado)),
                  },
                ]
              : [],
            categories: this.state.classData
              ? this.state.classData.slice(0, 7).map((lsn) => {
                  return document.documentElement.clientWidth < 500
                    ? lsn.ClassName.split("-")[0] + "..."
                    : document.documentElement.clientWidth < 700
                    ? [
                      `${lsn.ClassName.split("-")[1]}(${
                        lsn.ClassName.split("-")[2]
                      })`,
                    ]
                  : [lsn.ClassName.split("-")[1], lsn.ClassName.split("-")[2]];
                })
              : [],
          }}
          tableData={{
            header: {
              key: "Headers",
              items: t("chart").screens.subject.table.header.map(
                (header, key) => {
                  return {
                    content:
                      key && key === 1 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Presente"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Presente")}
                        />
                      ) : key === 2 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Ausente"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Ausente")}
                        />
                      ) : key === 3 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Retardo"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Retardo")}
                        />
                      ) : key === 4 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Justificado"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Justificado")}
                        />
                      ) : key === 5 ? (
                        <Button
                          text
                          style={{
                            padding: 0,
                            margin: 0,
                            minWidth: "30px",
                            color:
                              this.state.filterBy === "Total"
                                ? "#6264A7"
                                : "black",
                          }}
                          content={header}
                          onClick={() => this.filterByHandler("Total")}
                        />
                      ) : (
                        header
                      ),
                    key: "Header-" + header,
                  };
                }
              ),
            },
            rows: this.state.classData
              ? this.state.classData.map((lsn, key) => {
                  return {
                    key: lsn.ClassID + "key-" + key,
                    items: [
                      {
                        content: lsn.ClassName,
                        key: lsn.ClassID + "ClassName" + key,
                      },
                      {
                        content: lsn.Presente,
                        key: lsn.ClassID + "Presente" + key,
                      },
                      {
                        content: lsn.Ausente,
                        key: lsn.ClassID + "Ausente" + key,
                      },
                      {
                        content: lsn.Retardo,
                        key: lsn.ClassID + "Retardo" + key,
                      },
                      {
                        content: lsn.Justificado,
                        key: lsn.ClassID + "Justificado" + key,
                      },
                      { content: lsn.Total, key: lsn.ClassID + "Total" + key },
                    ],
                  };
                })
              : [],
          }}
          selectedExtension={this.props.selectedExtension}
          setSelectedExtension={this.props.setSelectedExtension}
        />
      )
    );
  }
}

export default withTranslation()(SubjectReport);
