import React from "react";
import axios from "axios";
import { object } from "prop-types";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { Dialog, Loader } from "@fluentui/react-northstar";

import EventForm from "../../components/forms/Event/index";
import Header from "../../components/Header/Header";
import EventFilters from "../../components/widgets/EventFilters";
import CustomProgressIndicator from "../../components/Loader/CustomProgressIndicator";

import userSvg from "../../Assets/images/svg/user.svg";
import { NotificationAlert } from "../../components/Notification/Notification";

const url = process.env.REACT_APP_EP_URL;

class NewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accDlg: false,
      categories: [],
      tags: [],
      teacherData: [],
      isSubmitting: false,
    };
  }

  componentDidMount() {
    this.getCategories();
    this.getTags();
    this.getTeachers();
  }

  getCategories = () => {
    axios
      .get(`${url}/api/category/all${this.props.user.slug}`)
      .then(({ data: { result } }) => {
        this.setState({
          categories: result,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  getTags = () => {
    axios
      .get(`${url}/api/tag/all${this.props.user.slug}`)
      .then(({ data: { result } }) => {
        this.setState({
          tags: result,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err.response);
      });
  };

  getTeachers() {
    axios
      .post(
        `${url}/api/teacher/get-teachers${this.props.user.slug}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + this.props.user.accessToken,
            // this.props.user.tokenType + " " + this.props.user.accessToken,
          },
        }
      )
      .then(({ data }) => {
        const teachers = data.result
          .map((teacher) => {
            return {
              header: teacher.TeacherName,
              image: teacher.UrlPhoto
                ? teacher.UrlPhoto + this.props.user.SASToken
                : userSvg,
              content: teacher.Facultad,
              key: teacher.TeacherEmailID,
            };
          })
          .filter((value, index, self) => self.indexOf(value) === index);

        this.setState({ teacherData: teachers });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log(err, err.response);
        if (err.response?.status === 401) {
          this.setState({ accDlg: true });
        }
      });
  }

  deleteEvent = async (eventId) => {
    try {
      axios.delete(`${url}/api/events/${eventId}${this.props.user.slug}`);
    } catch (error) {}
  };

  saveAttachments = async ({ eventId, uploadEventAttachmentsToAzure }) => {
    try {
      return await uploadEventAttachmentsToAzure(eventId);
    } catch (error) {
      NotificationAlert(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong",
        "error"
      );
      await this.deleteEvent(eventId);
      return;
    }
  };
  saveAttachments_ = async ({ eventId, uploadEventAttachmentsToAzure }) => {
    try {
      const attachments = await uploadEventAttachmentsToAzure(eventId);

      let headers = {
        Authorization: "Bearer " + this.props.user.accessToken,
      };

      return await axios({
        url: `${url}/api/event-attachments/save-event-attachments/${eventId}${this.props.user.slug}`,
        data: attachments,
        method: "POST",
        headers,
      });
    } catch (error) {
      NotificationAlert(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong",
        "error"
      );
      await this.deleteEvent(eventId);
      return;
    }
  };

  onSubmit = async (event, uploadEventAttachmentsToAzure) => {
    try {
      this.setState({ isSubmitting: true });
      event.teacherId = this.props.user.mail;
      if (!!event.speakers) {
        event.speakers = JSON.stringify(event.speakers);
      }
      const eventData = new FormData();
      Object.keys(event).forEach((e) => {
        if (e !== "attachments") {
          if (e === "categories") {
            for (let category of event[e]) {
              eventData.append("categories", category);
            }
          } else if (e === "tags") {
            for (let tag of event[e]) {
              eventData.append("tags", tag);
            }
          } else {
            eventData.append(e, event[e] || "");
          }
        } else {
          for (let file of event[e]) {
            eventData.append("attachments", file.originFileObj);
          }
        }
      });

      let headers = {
        Authorization: "Bearer " + this.props.user.accessToken,
      };

      try {
        let tokenData = null;
        let googleToken = localStorage.getItem("googleToken");
        let googleMeetToken = localStorage.getItem("GoogleMeetToken");
        let token = null;
        let googleMail = null;
        if (googleMeetToken && JSON.parse(googleMeetToken)?.accessToken) {
          tokenData = JSON.parse(googleMeetToken);
        } else if (googleToken && JSON.parse(googleToken)?.accessToken) {
          tokenData = JSON.parse(googleToken);
        }
        if (tokenData?.accessToken && tokenData.profileObj?.email) {
          token = tokenData.accessToken;
          googleMail = tokenData.profileObj.email;

          headers.AuthorizationForMeet = "Bearer " + token;
          eventData.append("googleMail", googleMail);
        }
      } catch (error) {}

      try {
        let tokenData = null;
        let MSTeamsToken = localStorage.getItem("MSTeamsToken");
        let token = null;
        let microsoftMail = null;
        if (MSTeamsToken && JSON.parse(MSTeamsToken)?.accessToken) {
          tokenData = JSON.parse(MSTeamsToken);
        }
        if (tokenData && tokenData.account?.idTokenClaims?.email) {
          token = tokenData.accessToken;
          microsoftMail = tokenData.account.idTokenClaims.email;

          headers.AuthorizationForMSTeams = "Bearer " + token;
          eventData.append("microsoftMail", microsoftMail);
        }
      } catch (error) {}

      const response = await axios.post(
        `${url}/api/events${this.props.user.slug}`,
        eventData,
        {
          headers,
        }
      );

      const eventId = response.data?.event?.id;

      if (response.data?.status === "Success" && eventId) {
        const attachmentResponseData = await this.saveAttachments({
          eventId,
          uploadEventAttachmentsToAzure,
        });

        if (attachmentResponseData?.data?.status?.toLowerCase() === "success") {
          this.props.history.push(`/events/my-events`);
        }
      }
    } catch (err) {
      NotificationAlert(
        err?.response?.data?.message || err?.message || "Something went wrong",
        "error"
      );
      console.log(err.response);
      if (err?.response?.status === 400 || err?.response?.status === 409) {
        // setDialog(true);
      }
      console.log(err);
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const props = this.props;

    const { categories, tags, teacherData, accDlg, isSubmitting } = this.state;
    const { t } = props;

    const translation = t("events");

    return (
      <div className="event-form-page">
        <Dialog
          open={accDlg}
          onConfirm={() => location.reload()}
          confirmButton={translation.common.authExpire.button}
          content={
            <p className="refreshMsg">
              {translation.common.authExpire.message}
            </p>
          }
          header={translation.common.authExpire.header}
        />
        <Header
          path="events"
          user={{ ...props.user }}
          logout={props.logout.bind()}
          accessToken={this.props.user.accessToken}
          getDb={props.getDb.bind()}
          switchDb={props.switchDb}
          selectedExtension={props.selectedExtension}
          setSelectedExtension={props.setSelectedExtension}
          openWorkSpace={false}
        />
        <EventFilters currentPage="createEvent" />
        <CustomProgressIndicator
          isLoading={isSubmitting}
          style={{
            progressTrack: {
              backgroundColor: "#ffffff",
            },
          }}
        />

        <div className="event wrapper">
          <EventForm
            {...this.props}
            isNew={true}
            onSubmit={this.onSubmit}
            onCancel={() => this.props.history.push("/events/my-events")}
            categories={categories}
            tags={tags}
            teacherData={teacherData}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    );
  }
}

NewEvent.propTypes = {
  query: object,
};

export default withTranslation()(NewEvent);
