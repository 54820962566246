import React from "react";
import Header from "../../components/Header/Header";
import { Flex } from "@fluentui/react-northstar";

const AiTutor = (props) => {
 const postCrossDomainMessage = () => {
    const iframeEl = document.getElementsByClassName("ai-tutor-app");
    const user = {
      accessToken: props.user.accessToken,
      email: props.user.mail,
      role: props.user.actualRole,
    };
    // Make sure you are sending a string, and to stringify JSON
    iframeEl[0]?.contentWindow?.postMessage(
      {
        source: "ai-tutor",
        message: { user, isFromACV: true },
      },
      process.env.REACT_APP_EP_AI_TUTOR_URL
    );
  };
  return (
    <Flex column style={{ height: "100vh" }}>
      <Header
        path="ai-tutor"
        className="ai-tutor-app"
        user={props.user}
        logout={props.logout}
        accessToken={props.user.accessToken}
        getDb={props.getDb.bind()}
        switchDb={props.switchDb}
        selectedExtension={props.selectedExtension}
        setSelectedExtension={props.setSelectedExtension}
        openWorkSpace={false}
      />
      <Flex style={{ flexGrow: 1 }}>
        <iframe
          src={process.env.REACT_APP_EP_AI_TUTOR_URL}
          className="ai-tutor-app"
          style={{ width: "100%" }}
          allow="fullscreen; camera; microphone; clipboard-read; clipboard-write"
          title="AiTutor"
          onLoad={postCrossDomainMessage}
          onError={(error) =>
            console.error("Error during authentication", error)
          }
        />
      </Flex>
    </Flex>
  );
};

export default AiTutor;
