import React, { useEffect, useState } from "react";
import "./PublicProfile.scss";
import {
  Button,
  CloseIcon,
  Dialog,
  Flex,
  FlexItem,
  Loader,
  Text,
} from "@fluentui/react-northstar";
import { NotificationAlert } from "../../../../../../components/Notification/Notification";
import axios from "axios";
import { useBooking } from "../../../../../../context/BookingContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const url = process.env.REACT_APP_EP_URL;

const PublicProfile = (props) => {
  const { t } = useTranslation();
  const {
    userProfileData,
    isLoadingData,
    setUserProfileData,
    getProfileData,
    user,
  } = useBooking();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditPublicProfile, setIsEditPublicProfile] = useState(false);
  const [removedPublicProfileData, setRemovedPublicProfileData] = useState({
    skills: [],
    interests: [],
    askMeAbout: [],
  });

  const isRemovedPublicProfileData = Object.values(
    removedPublicProfileData
  ).some((item) => item.length > 0);

  const removeSkill = (id) => {
    setRemovedPublicProfileData({
      ...removedPublicProfileData,
      skills: [...removedPublicProfileData.skills, id],
    });
  };

  const removeInterest = (id) => {
    setRemovedPublicProfileData({
      ...removedPublicProfileData,
      interests: [...removedPublicProfileData.interests, id],
    });
  };

  const removeAskMeAbout = (id) => {
    setRemovedPublicProfileData({
      ...removedPublicProfileData,
      askMeAbout: [...removedPublicProfileData.askMeAbout, id],
    });
  };

  const handleSavePublicProfile = async () => {
    setIsLoading(true);
    const payload = {
      specialities: userProfileData.askMeAbout
        .filter(
          (item) => !removedPublicProfileData.askMeAbout.includes(item.id)
        )
        .map((item) => item.id),
      skills: userProfileData.skills
        .filter((item) => !removedPublicProfileData.skills.includes(item.id))
        .map((item) => item.id),
      interests: userProfileData.interests
        .filter((item) => !removedPublicProfileData.interests.includes(item.id))
        .map((item) => item.id),
    };
    try {
      const { data } = await axios.put(
        `${url}/api/tutor/update-public-profile/${user.slug}`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
          },
        }
      );
      if (data.status) {
        toast.success("Public profile updated successfully!");
        getProfileData();
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const translation = t("booking").setting.publicProfileTab;

  return (
    <Flex space="between" className="public-profile-wrapper">
      <Flex column gap="gap.large" className="public-profile-container">
        <Flex column gap="gap.small" className="ask-me-about-container">
          <FlexItem>
            <Text weight="bold" content={translation.askMeAbout} />
          </FlexItem>
          <FlexItem grow>
            <div className="ask-me-about-tags-container">
              {isLoadingData ? (
                <Flex
                  vAlign="center"
                  hAlign="center"
                  className="loader-container"
                >
                  <Loader />
                </Flex>
              ) : userProfileData?.askMeAbout?.length > 0 ? (
                <Flex wrap gap="gap.smaller">
                  {userProfileData?.askMeAbout
                    ?.filter(
                      (item) =>
                        !removedPublicProfileData.askMeAbout.includes(item.id)
                    )
                    .map((item) => (
                      <Flex
                        key={item.id}
                        content={item.title}
                        className="ask-me-about-tag"
                        vAlign="center"
                        hAlign="center"
                      >
                        <Text content={item.title} weight="semibold" />

                        <Button
                          text
                          iconOnly
                          icon={<CloseIcon size="small" />}
                          disabled={isLoading || !isEditPublicProfile}
                          onClick={() => removeAskMeAbout(item.id)}
                        />
                      </Flex>
                    ))}
                </Flex>
              ) : (
                <Flex
                  vAlign="center"
                  hAlign="center"
                  className="loader-container"
                >
                  <Text content={translation.noAskMeAbout} />
                </Flex>
              )}
            </div>
          </FlexItem>
        </Flex>
        <Flex column gap="gap.small" className="skills-container">
          <FlexItem>
            <Text weight="bold" content={translation.skills} />
          </FlexItem>
          <FlexItem grow>
            <div className="skills-tags-container">
              {isLoadingData ? (
                <Flex
                  vAlign="center"
                  hAlign="center"
                  className="loader-container"
                >
                  <Loader />
                </Flex>
              ) : userProfileData?.skills?.length > 0 ? (
                <Flex wrap gap="gap.smaller">
                  {userProfileData?.skills
                    ?.filter(
                      (item) =>
                        !removedPublicProfileData.skills.includes(item.id)
                    )
                    .map((item) => (
                      <Flex
                        key={item.id}
                        content={item.title}
                        className="skill-tag"
                        vAlign="center"
                        hAlign="center"
                      >
                        <Text content={item.title} weight="semibold" />

                        <Button
                          text
                          iconOnly
                          icon={<CloseIcon size="small" />}
                          disabled={isLoading || !isEditPublicProfile}
                          onClick={() => removeSkill(item.id)}
                        />
                      </Flex>
                    ))}
                </Flex>
              ) : (
                <Flex
                  vAlign="center"
                  hAlign="center"
                  className="loader-container"
                >
                  <Text content={translation.noSkills} />
                </Flex>
              )}
            </div>
          </FlexItem>
        </Flex>
        <Flex column gap="gap.small" className="interests-container">
          <FlexItem>
            <Text weight="bold" content={translation.interests} />
          </FlexItem>
          <FlexItem grow>
            <div className="interests-tags-container">
              {isLoadingData ? (
                <Flex
                  vAlign="center"
                  hAlign="center"
                  className="loader-container"
                >
                  <Loader />
                </Flex>
              ) : userProfileData?.interests?.length > 0 ? (
                <Flex wrap gap="gap.smaller">
                  {userProfileData?.interests
                    ?.filter(
                      (item) =>
                        !removedPublicProfileData.interests.includes(item.id)
                    )
                    .map((item) => (
                      <Flex
                        key={item.id}
                        content={item.title}
                        className="interest-tag"
                        vAlign="center"
                        hAlign="center"
                      >
                        <Text content={item.title} weight="semibold" />
                        <Button
                          text
                          iconOnly
                          icon={<CloseIcon size="small" />}
                          disabled={isLoading || !isEditPublicProfile}
                          onClick={() => removeInterest(item.id)}
                        />
                      </Flex>
                    ))}
                </Flex>
              ) : (
                <Flex
                  vAlign="center"
                  hAlign="center"
                  className="loader-container"
                >
                  <Text content={translation.noInterests} />
                </Flex>
              )}
            </div>
          </FlexItem>
        </Flex>
      </Flex>
      {isEditPublicProfile ? (
        <Flex gap="gap.small">
          <Button
            secondary
            onClick={() => {
              setIsEditPublicProfile(false);
              setRemovedPublicProfileData({
                skills: [],
                interests: [],
                askMeAbout: [],
              });
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            primary
            className="save-details-button"
            onClick={handleSavePublicProfile}
            disabled={isLoading || !isRemovedPublicProfileData}
          >
            {isLoading ? (
              <Flex vAlign="center" gap="gap.small">
                <Text content={`${translation.saving}...`} />
                <Loader size="small" />
              </Flex>
            ) : (
              translation.saveChanges
            )}
          </Button>
        </Flex>
      ) : (
        <Button
          primary
          onClick={() => {
            setIsEditPublicProfile(true);
          }}
          disabled={isLoadingData}
        >
          Edit
        </Button>
      )}
    </Flex>
  );
};

export default PublicProfile;
