import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTutorRequest } from "../../../../../context/TutorRequestContext";
import styled from "styled-components";
import {
  AcceptIcon,
  Button,
  EyeIcon,
  Flex,
  Loader,
  Text,
  Tooltip,
  TrashCanIcon,
} from "@fluentui/react-northstar";
import "./index.scss";
import { Tag } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ConfirmModel from "../../../../Booking/Components/TutorRequest/components/ConfirmModel/ConfirmModel";
import TutorInfoModel from "../TutorInfoModel/TutorInfoModel";

const Card = styled(Flex)`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  border-radius: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const TutorRequests = () => {
  const { t } = useTranslation();
  const {
    tutorRequests,
    isTutorRequestsLoading,
    getAllPendingTutorRequests,
    setTutorRequestToUpdate,
    setIsOpenConfirmationModal,
    getTutorInfo,
    setIsOpenTutorInfoModal,
  } = useTutorRequest();

  const translation = t("booking").setting.tutorRequest.manageRequests;

  useEffect(() => {
    getAllPendingTutorRequests();
  }, []);

  const getTagByStatus = (status) => {
    const color =
      status === "pending"
        ? "warning"
        : status === "approved"
        ? "success"
        : "error";

    const icon =
      status === "pending" ? (
        <ClockCircleOutlined />
      ) : status === "approved" ? (
        <CheckCircleOutlined />
      ) : (
        <CloseCircleOutlined />
      );
    return (
      <Tag icon={icon} color={color}>
        <Text style={{ textTransform: "capitalize" }} content={status} />
      </Tag>
    );
  };

  return (
    <Flex column className="tutor-requests-wrapper">
      <ConfirmModel />
      <TutorInfoModel />
      <Card className="ms-Grid-row my-3">
        <div className="ms-Grid-col ms-sm12 ms-lg6  ">{translation.email}</div>
        <div className="ms-Grid-col ms-sm12 ms-lg2">{translation.role}</div>
        <div className="ms-Grid-col ms-sm12 ms-lg2">{translation.status}</div>

        <Flex hAlign="center" className="ms-Grid-col ms-sm12 ms-lg2">
          {translation.actions}
        </Flex>
      </Card>
      {isTutorRequestsLoading ? (
        <Flex
          hAlign="center"
          vAlign="center"
          style={{ height: "150px", width: "100%" }}
        >
          <Loader />
        </Flex>
      ) : tutorRequests.length ? (
        tutorRequests.map((request, i) => {
          return (
            <Card className="ms-Grid-row my-3" key={`tutor-${i}`}>
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <Tooltip
                  content={request.email}
                  trigger={<Text content={request.email} />}
                />
              </div>

              <div className="ms-Grid-col ms-sm12 ms-lg2">
                <Text content={request.role} />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg2">
                {getTagByStatus(request.status)}
              </div>

              <div className="ms-Grid-col ms-sm12 ms-lg2 text-right pr-3">
                <Flex gap="gap.smaller" hAlign="center">
                  <Button
                    iconOnly
                    // style={{ color : "#52c41a" }}
                    icon={<EyeIcon />}
                    onClick={() => {
                      setIsOpenTutorInfoModal(true);
                      getTutorInfo(request.email, request.role);
                    }}
                  />
                  <Button
                    iconOnly
                    style={{ color: "#52c41a" }}
                    icon={<AcceptIcon />}
                    onClick={() => {
                      setIsOpenConfirmationModal(true);
                      setTutorRequestToUpdate({
                        id: request.id,
                        status: "approved",
                      });
                    }}
                  />
                  <Button
                    iconOnly
                    style={{ color: "#ff4d4f" }}
                    icon={<CloseOutlined />}
                    onClick={() => {
                      setIsOpenConfirmationModal(true);
                      setTutorRequestToUpdate({
                        id: request.id,
                        status: "cancelled",
                      });
                    }}
                  />
                </Flex>
              </div>
            </Card>
          );
        })
      ) : (
        <Flex
          hAlign="center"
          vAlign="center"
          style={{ height: "150px", width: "100%" }}
        >
          <p>{translation.noRequests}</p>
        </Flex>
      )}
    </Flex>
  );
};

export default TutorRequests;
